import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Fuse from 'fuse.js';
import AsyncCreatableSelect from 'react-select/async-creatable';


const selectOptions = ({ options, onSelect, value = null, name = null, type = null }) => {
  console.log("from new component", options, "  new value      ", value)
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(options);
  }, [options])

  const searchParam = {
    keys: ['label'],
  };

  const fuse = new Fuse(options, searchParam);

  const searchResult = (inputValue) => {
    return new Promise((resolve) => {
      const res = fuse.search(inputValue).map((result) => result.item);
      resolve(res);
    });
  };
  return (
    <>
      {type == 'creatable' ?
        <AsyncCreatableSelect
          isClearable
          maxMenuHeight={150}
          menuPlacement="auto"
          defaultValue={value ? { value: value, label: value } : null}
          value={value ? { value: value, label: value } : null}
          cacheOptions
          defaultOptions={option}
          placeholder={`select ${name}`}
          loadOptions={searchResult}
          onChange={(event) => onSelect(event?.value)}
          styles={{ option: (style) => ({ ...style, color: 'black' }) }}
        />
        :
        <AsyncSelect
          isClearable
          maxMenuHeight={150}
          menuPlacement="auto"
          defaultValue={value ? { value: value, label: value } : null}
          value={value ? { value: value, label: value } : null}
          cacheOptions
          defaultOptions={option}
          placeholder={`select ${name ? name : ''}`}
          loadOptions={searchResult}
          onChange={(event) => onSelect(event?.value)}
          styles={{ option: (style) => ({ ...style, color: 'black' }) }}
        />
      }
    </>
  )

}
export default selectOptions;
